<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search email"
            />
          </b-input-group>
        </div>
      </div>
      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <div class="d-block d-lg-none bg-white p-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <aom-skeleton-loader v-if="isLoading" />
        <ul class="email-media-list">
          <b-media
            v-for="email in emailList"
            :key="email.id"
            tag="li"
            no-body
            :class="{ 'mail-read': !getIsUnreadMessage(email) }"
            @click="viewTopicMessage(email.id)"
          >
            <b-media-body>
              <div class="mail-details m-0">
                <div
                  class="mail-items"
                >
                  <h5 class="m-0">
                    <img
                      v-if="email.created_by && email.created_by.profile_image && email.created_by.profile_image.url"
                      :src="email.created_by.profile_image.url"
                      alt="profile_image"
                      class="rounded-circle"
                      style="width: 2.5rem; height: 2.5rem"
                    >
                    <b-avatar
                      v-else
                      :text="userInitials(email)"
                      size="2.5rem"
                    />
                    <span class="font-weight-bold">
                      {{ getUserFullName(email) }}
                    </span>
                    <span
                      class="d-none d-sm-inline ml-1"
                      :class="getUnreadMessageClasses(email)"
                    >
                      {{ (isChampionReply(email) && email.latest_message[0].subject.indexOf("Re:") != 0? "Re:": "") + email.latest_message[0].subject }}
                    </span>
                    <!-- Subject for mobile -->
                    <p
                      class="d-block d-sm-none mt-1"
                      :class="getUnreadMessageClasses(email)"
                    >
                      {{ (isChampionReply(email) && email.latest_message[0].subject.indexOf("Re:") != 0? "Re:": "") + email.latest_message[0].subject }}
                    </p>
                  </h5>
                  <!-- <span class="text-truncate">{{ email.name }}</span> -->
                </div>
                <div class="mail-type">
                  <b-badge :variant="['Match', 'Direct'].indexOf(getTopicLabel(email, matchedUsers)) !== -1 ? 'light-primary': 'light-success'">
                    {{ getTopicLabel(email, matchedUsers) }}
                  </b-badge>
                </div>
                <div class="mail-meta-item">
                  <span class="mail-date">{{ receivedAtDate(email) }}</span>
                </div>
              </div>

              <div
                v-if="email.messages"
                class="mail-message"
              >
                <p class="text-truncate mb-0">
                  {{ email.messages.map(m => m.subject).join('') }}
                </p>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
          :class="{'show': !emailList.length && !isLoading}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Email View/Detail -->
    <email-view
      :class="{'show': showEmailDetails}"
      :is-loading="isLoadingMsg"
      :email-view-data="emailViewData"
      :recipients="emailRecipients"
      :topic="topic"
      @close-email-view="onCloseEmailView"
      @emailAdded="viewTopicMessage"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
    <!-- Compose Email Modal -->
    <email-compose
      v-model="shallShowEmailComposeModal"
      :is-program-communications="true"
      @emailAdded="fetchProgramEmails"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ref
} from '@vue/composition-api';
import {
  BMedia,
  BMediaBody, 
  BAvatar, 
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { formatDateToMonthShort } from '@core/utils/filter';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
import EmailLeftSidebar from './EmailLeftSidebar.vue';
import EmailView from './EmailView.vue';
import { commsService } from '@services';
import AomSkeletonLoader from '@aom-core/AomSkeletonLoader.vue';
import { makeErrorToast } from "@/libs/utils";
import { recipientTypes } from '@models';
import _debounce from "lodash/debounce";
import EmailCompose from "./EmailCompose.vue";
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import { userRoles, userStatus } from "@/models";
import useCommunications from "@/views/apps/useCommunications";
import { usersService } from "@/services";
import { senderIsChampionOrAdmin } from "@/@core/utils/utils";
export default {
  components: {
    BMedia,
    BMediaBody,
    BAvatar,
    VuePerfectScrollbar,
    EmailLeftSidebar,
    EmailView,
    EmailCompose,
    AomSkeletonLoader,
    BBadge,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput
  },
  data() {
    return {
      isLoading: false,
      isLoadingMsg: false,
      emailList: [],
      matchedUsers: [],
      showEmailDetails: false,
      emailViewData: [],
      emailRecipients: [],
      emailsTotal: 0,
      searchQuery:undefined,
      topic: {},
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),

    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },
    currentUser() {
      return this.$store.getters['profile/profile'];
    },
  },
  watch: {
    '$route.query': {
      handler: function() {
        if (this.$route.query.userId) {
          this.shallShowEmailComposeModal = true;
        }
      },
      deep: true,
      immediate: true
    },
    searchQuery: _debounce(function (value) {
      this.fetchProgramEmails({
        columnFilters: [{
          field: 'messages',
          value: value,
        }],
      });
    }, 1000),
  },
  async created () {
    await this.fetchListUserMatches();
    await this.fetchProgramEmails();

    if (this.$route.params.messageId) {
      this.viewTopicMessage(Number(this.$route.params.messageId));
    }
  },
  methods: {
    receivedAtDate(email) {
      if (email.topic_sent_messages?.length) {
        return this.formatDate(email.topic_sent_messages[0].updated_at);
      }
      return this.formatDate(email.updated_at);
    },
    isChampionReply(topic) {
      if (topic.latest_message.length > 1) {
        let user = topic.latest_message[0].user;
        return senderIsChampionOrAdmin(user?.user_roles || [])
      }
      return false;
    },
    getUserFullName(email) {
      let user = email.created_by;
      if (this.isChampionReply(email)) {
        user = email.latest_message[0].user
      }
      if (user) {
        if (senderIsChampionOrAdmin(user.user_roles)) {
          return "Champion";
        }
        const roles = user.user_roles.filter(r => {
          return [userRoles.CHAMPION_CANDIDATE, userRoles.PARTICIPANT_CANDIDATE].indexOf(r.role_id) == -1;
        });
        if (roles.length === 0) {
          return "User Removed";
        } else {
          const isActive = roles.filter(r => r.status_id === userStatus.ACTIVE).length > 0;
          if (!isActive) {
            return "User Deactivated";
          }
        }
        return user.full_name;
      }
      return email.created_by_id !== null? "User Removed": "";
    },
    async fetchListUserMatches() {
      try {
        const programId = this.appCurrentProgram.id;
        const response = await usersService.getListUserMatches(programId, this.currentUser.id);
        const {items } = response.data;
        this.matchedUsers = items.map(i => {
          let users = i.match_users.filter(i => i.user_id !== this.currentUser.id);
          return users.length? users[0]: null;
        });
      } catch (e) {
        this.$log.error(e);
      }
    },
    async fetchProgramEmails(searchQuery) {
      try {
        this.isLoading = true;
        const programId = this.appCurrentProgram.id;
        this.$store.dispatch('programs/FETCH_COUNT_MESSAGES_UNREAD', programId);
        const response = await commsService.getProgramsComms(programId, searchQuery);
        const {items, total} = response.data;
        this.emailList = items.sort(function compare(a, b) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        this.emailsTotal = total;
      } catch (e) {
        this.$toast(makeErrorToast("Program emails not loaded"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async viewTopicMessage (topicId) {
      try {
        const topic = this.emailList.find(topic => topic.id === topicId);
        this.topic = topic;
        this.isLoadingMsg = true;
        this.showEmailDetails = true;
        const programId = this.appCurrentProgram.id;
        const response = await commsService.getTopicMessageList(programId, topicId);
        const {items} = response.data;
        this.emailViewData = items;
        this.emailRecipients = this.emailList.filter(e => e.id === topicId);
        this.updateTopicMessageMarkRead(topicId);
      } catch (e) {
        this.$toast(makeErrorToast("Could not fetch Email."));
        this.$log.error(e);
      } finally {
        this.isLoadingMsg = false;
      }
    },
    async updateTopicMessageMarkRead(topicId) {
      try {
        const programId = this.appCurrentProgram.id;
        const topic = this.emailList.find(topic => topic.id === topicId);
        if (topic && this.getIsUnreadMessage(topic)) {
          await commsService.postTopicMessageMarkRead(programId, {topics: [{id: topicId}]});
          this.$store.dispatch('programs/FETCH_COUNT_MESSAGES_UNREAD', programId);
        }
      } catch (e) {
        this.$log.error(e);
      }
    },
    filterEmailList() {
      _debounce(() => {
        this.fetchProgramEmails(searchQuery);
      }, 1000);
    },
    formatDate(date) {
      return formatDateTimeFromIsoDateTime(date, true, 'MMM DD, HH:mm');
    },
    userInitials(email) {
      const name = this.getUserFullName(email).split(' ');
      const firstChar = name.length? Array.from(name[0])[0]: "";
      const lastChar = name.length > 1? Array.from(name[1])[0]: "";
      return `${firstChar}${lastChar}`;
    },
    onCloseEmailView() {
      this.showEmailDetails = false;
      this.fetchProgramEmails();
    },
    getIsUnreadMessage(topic) {
      // Message always read if current user is sender
      if (topic?.latest_message[0]?.sender_id === this.profile.id) {
        return false;
      }
      return topic.receipient_has_unread_messages;
    },
    getUnreadMessageClasses(topic) {
      const classes = [];
      if (this.getIsUnreadMessage(topic)) {
        classes.push('text-primary');
        classes.push('font-weight-bold');
      }
      return classes;
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const { getTopicLabel } = useCommunications();

    return {
      perfectScrollbarSettings,
      formatDateToMonthShort,
      shallShowEmailComposeModal,
      mqShallShowLeftSidebar,
      recipientTypes,
      getTopicLabel
    };
  },
};
</script>

<style lang="scss" scoped>
  @use "sass:map";
  @import '~@/assets/scss/base/bootstrap-extended/_variables.scss';

  .mail-type {
    margin-right: 20px;
    margin-left: auto;
  }

  @media (min-width: #{map.get($container-max-widths, "md")}) {
    .email-app-list {
      background-color: #fff;
    }
  }
</style>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>
