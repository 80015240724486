import client from './http';

class FormsService {

  constructor(axios) {
    this.axios = axios;
  }

  // Application Sets
  getApplicationList(search) {
    return this.axios.get(`/library/application-sets`,{params: {search: search}});
  }
  deleteApplicationSet(id) {
    return this.axios.delete(`/library/application-sets/${id}`);
  }
  createApplicationSet(set) {
    return this.axios.post(`/library/application-sets`, set);
  }
  getApplicationSet(id) {
    return this.axios.get(`/library/application-sets/${id}`);
  }
  updateApplicationSet(id, set) {
    return this.axios.patch(`/library/application-sets/${id}`, set);
  }
  createLibraryApplicationSetMatchedPair(id, set) {
    return this.axios.post(`/library/application-sets/${id}/questions/pairs`, set);
  }
  duplicateLibraryApplicationSet(id) {
    return this.axios.post(`/library/application-sets/${id}/duplicate`);
  }
  // program forms
  updateProgramApplicationSet(programId, id, set) {
    return this.axios.patch(`/programs/${programId}/application-sets/${id}`, set);
  }

  // Surveys
  getSurveysList(search) {
    return this.axios.get(`/library/surveys`,{params: {search: search}});
  }
  createSurvey(survey) {
    return this.axios.post(`/library/surveys`, survey);
  }
  deleteSurvey(id) {
    return this.axios.delete(`/library/surveys/${id}`);
  }
  getSurvey(id) {
    return this.axios.get(`/library/surveys/${id}`);
  }
  updateSurvey(id, survey) {
    return this.axios.patch(`/library/surveys/${id}`, survey);
  }
  duplicateSurvey(id) {
    return this.axios.post(`/library/surveys/${id}/duplicate`);
  }

  // program survey
  createProgramSurvey(programId, survey) {
    return this.axios.post(`/programs/${programId}/surveys`, survey);
  }
  deleteProgramSurvey(programId, id) {
    return this.axios.delete(`/programs/${programId}/surveys/${id}`);
  }
  getProgramSurvey(programId, id) {
    return this.axios.get(`/programs/${programId}/surveys/${id}`);
  }
  updateProgramSurvey(programId, id, survey) {
    return this.axios.patch(`/programs/${programId}/surveys/${id}`, survey);
  }
  getProgramSurveyMetric(programId, id, groupId) {
    return this.axios.get(`/programs/${programId}/surveys/${id}/metrics`, {params: {group_id: groupId}});
  }
  getProgramSurveyResult(programId, id, search) {
    return this.axios.get(`/programs/${programId}/surveys/${id}/results`, {params: {search: search}});
  }
  getProgramApplicationSetResult(programId, id, search) {
    return this.axios.get(`/programs/${programId}/application-sets/${id}/results`, {params: {search: search}});
  }
  downloadSurveyResult(programId, id, type) {
    return this.axios.get(`/programs/${programId}/surveys/${id}/results/download/${type}`);
  }
  sendSurveyReminders(programId, userId, surveyId, userFormId) {
    return this.axios.post(`/programs/${programId}/users/${userId}/surveys/${surveyId}/remind/${userFormId}`);
  }
  sendMultiParticipantsReminder(programId, surveyId, users) {
    return this.axios.post(`/programs/${programId}/surveys/${surveyId}/users/participants/remind`, users);
  }
  duplicateProgramSurvey(programId, surveyId) {
    return this.axios.post(`/programs/${programId}/surveys/${surveyId}/duplicate`);
  }
  updateProgramSurveyStatus(programId, surveyId, status) {
    return this.axios.patch(`/programs/${programId}/surveys/${surveyId}/status`, status);
  }


  // Individual program survey questions
  createLibraryFormQuestion(form, question) {
    return this.axios.post(`/library/surveys/${form}/questions`, question);
  }
  deleteLibraryFormQuestion(form, id) {
    return this.axios.delete(`/library/surveys/${form}/questions/${id}`);
  }
  updateLibraryFormQuestion(form, id, question) {
    return this.axios.patch(`/library/surveys/${form}/questions/${id}`, question);
  }
  createProgramFormQuestion(programId, formId, question) {
    return this.axios.post(`/programs/${programId}/surveys/${formId}/questions`, question);
  }
  deleteProgramFormQuestion(programId, formId, id) {
    return this.axios.delete(`/programs/${programId}/surveys/${formId}/questions/${id}`);
  }
  updateProgramFormQuestion(programId, formId, questionId, question) {
    return this.axios.patch(`/programs/${programId}/surveys/${formId}/questions/${questionId}`, question);
  }
  syncSubmissions(programId, applicationSetId) {
    return this.axios.post(`/programs/${programId}/application-sets/${applicationSetId}/sync-submissions`);
  }
}

const formsService = new FormsService(client);
export default formsService;
