import { extend, localize } from 'vee-validate';
import {
  max as rule_max,
  numeric as rule_numeric,
  size as file_size,
  min_value as min_value,
  max_value as max_value,
  confirmed
} from 'vee-validate/dist/rules';
import { required, email, min } from '@core/utils/validations/validations.js';
import { locales, localesDisplay } from '@models';
import DOMPurify from 'dompurify';
 

const max = extend('max', rule_max);
const numeric = extend('numeric', rule_numeric);
const size = extend('size', file_size);
const minValue = extend('min_value', min_value);
const maxValue = extend('max_value', max_value);
//usage rules="not_contains:@field"
const notContains = extend('not_contains', {
  params: ['other'],
  message: 'The {_field_} field cannot contain the {other} field value',
  validate: (value, { other }) => {
    return value.indexOf(other) === -1;
  }
});

const confirmedPassword = extend('confirmed_password', {
  ...confirmed,
  message: 'The {_field_} field does not match',
});

const strongPassword = extend('strong_password', {
  message: 'The {_field_} field must have at least three of any of the following: a lowercase letter; an uppercase letter; a number; a special character e.g. !#.$',
  validate: value => {
    return [
      /[a-z]/.test(value),
      /[A-Z]/.test(value),
      /[0-9]/.test(value),
      /[^0-9a-zA-Z]/.test(value)
    ].reduce((prev, current) => current ? prev + 1 : prev, 0) >= 3;
  }
});

const validateAomPhone = extend('aom_phone', value => {
  if (value === undefined || value === null || value.length === 0) {
    return true;
  }
  // Validate format
  const regEx = /^\+[1-9][\d]*$/;
  if (!regEx.test(value)) {
    return 'Enter a valid phone number using numbers only';
  }

  // Validate length
  if (!value || value.length < 8 || value.length > 15) {
    return 'Incorrect mobile phone number';
  }

  return true;
});


const AomSanitize = extend('aom_sanitize', value => {
  const sanitizedValue = DOMPurify.sanitize(value);
  return sanitizedValue === value || 'Input contains invalid characters';
});



const urlWithProtocol = extend('url_with_protocol', {
  message: 'Please enter a full url including the http(s) protocol.',
  validate(value, args) {
    if (value === undefined || value === null || value.length === 0) {
      return true;
    }
    /* eslint-disable no-useless-escape */
    const re = /^http[s]?:\/\/(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    /* eslint-enable no-useless-escape */
    return re.test(value);
  }
});

const validateMultipleEmails = extend('email_multi', {
  message: 'The {_field_} field must be valid emails',
  validate(value, args) {
    const regEx = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+(([ ]*[;][ ]*)+(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
    return regEx.test(value);
  }
});

const validateProgramLanguages = extend('program_languages', {
  message: `The {_field_} must include ${localesDisplay[locales.EN]}`,
  validate(value, args) {
    const hasEnglish = value.find(lang => lang.id === locales.EN);
    return !!hasEnglish;
  }
});

export {
  required,
  email,
  min,
  max,
  numeric,
  notContains,
  strongPassword,
  size,
  urlWithProtocol,
  minValue,
  validateMultipleEmails,
  validateProgramLanguages,
  // eslint-disable-next-line camelcase 
  max_value,
  validateAomPhone,
  confirmedPassword,
  AomSanitize
};
