<template>
  <div v-if="isLoading">
    <question-skeleton-loader />
  </div>
  <b-card v-else>
    <div v-if="resource.type_id === resourceTypes.WEB_PAGE">
      <!-- eslint-disable vue/no-v-html -->
      <b-card class="mb-1">
        <b-row>
          <b-col
            sm="10"
            class="text-left"
          >
            <div v-html="DOMPurify.sanitize(resource.translations[0].page_title)" />
          </b-col>
          <b-col
            sm="2"
            class="text-right"
          >
            <feather-icon
              v-if="resource.is_public"
              icon="Share2Icon"
              size="20"
              style="cursor: pointer;" 
              @click="copyResourcePublicLink"
            />
            <feather-icon
              class="ml-2"
              icon="PrinterIcon"
              size="20"
              style="cursor: pointer;"
              @click="printWebpage"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card
        id="content-to-print"
        class="mb-1"
      >
        <b-row>
          <b-col
            sm="12"
            class="text-left"
          >
            <div v-html="DOMPurify.sanitize(resource.translations[0].page_content)" />
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="resource.is_library">
        <b-row>
          <b-col
            sm="4"
            class="text-center"
          >
            info@artofmentoring.net
          </b-col>
          <b-col
            sm="4"
            class="text-center"
          >
            www.artofmentoring.net
          </b-col>
          <b-col
            sm="4"
            class="text-center"
          >
            {{ new Date().getFullYear() }} Art of Mentoring Holdings Pvt Ltd
          </b-col>
        </b-row>
      </b-card>
      <!-- eslint-disable -->
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue";
import QuestionSkeletonLoader from "@/views/components/questions/QuestionSkeletonLoader.vue";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import resourcesService from "@/services/resourcesService";
import { resourceTypes } from "@/models";
import { AOM_MAIN_DOMAIN, AOM_PORTAL_SUB_DOMAIN } from '@/constants/app';
import DOMPurify from 'dompurify';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    QuestionSkeletonLoader,
  },
  props: {
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      resource: null,
      isLoading: false,
      resourceTypes,
      defaultThumbnail: require("@/assets/images/elements/default-resource-thumbnail.jpg")
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'programById', 'defaultProgramId']),
    ...mapGetters("app", [
      "isAdminPortal"
    ]),
  },
  created() {
    this.fetchResource();
  },
  methods: {
    printWebpage() {
      window.print();
    },
    copyResourcePublicLink() {
      if (window.navigator) {
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}/${this.defaultProgram.client.path}/${this.defaultProgram.path}/resources/${this.resource.id}/public`;
        window.navigator.clipboard.writeText(link);
        this.$toast(makeSuccessToast("Copied to clipboard."));
      }
    },
    async fetchResource() {
      try {
        this.isLoading = true;
        const response = this.isAdminPortal? 
          await resourcesService.getLibraryResourceById(
            this.$route.params.resource
          ): 
          await resourcesService.getProgramResourceById(
            this.defaultProgramId,
            this.$route.params.resource
          );

        if (response && response.data) {
          this.resource = response.data;
        }
      } catch (e) {
        this.$toast(
          makeErrorToast(`Unable to fetch Program ${this.resourceId}.`)
        );
        this.$log(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  setup() {
    return {
      DOMPurify
    };
  }
};
</script>
<style lang="scss">
.card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
@media print {
    body *{
        visibility: hidden;
    }

    #content-to-print, #content-to-print * {
        visibility: visible;
    }

    #content-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}
</style>
