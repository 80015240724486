var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"compose-mail","visible":_vm.shallShowEmailComposeModal,"title":"Compose Email","modal-class":"modal-sticky","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","no-fade":"","hide-backdrop":"","static":"","scrollable":"","no-enforce-focus":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":_vm.discardEmail}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('action-button',{attrs:{"variant":"primary","right":""},on:{"click":_vm.sendEmail}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer",attrs:{"icon":"SendIcon"}}),(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.submitButtonText))])],1)],1),_c('div',[(_vm.communication && _vm.communication.is_schedulable && !_vm.isProgramTemplateComms)?_c('action-button',{staticClass:"mr-2",attrs:{"variant":"primary","right":"","disabled":!_vm.canSendNow || _vm.isScheduledSending},on:{"click":_vm.sendNow}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer",attrs:{"icon":"SendIcon"}}),(_vm.isScheduledSending)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Send now")])],1):_vm._e(),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.testSendEmail}},[_vm._v(" "+_vm._s(_vm.sendTestText)+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendEmail)}}},[(_vm.type === _vm.emailComposeTypes.SCHEDULED)?[_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"name-internal"}},[_vm._v("Name (Internal): ")]),_c('validation-provider',{ref:"emailName",staticClass:"w-100",attrs:{"rules":_vm.isAdminPortal ? 'required' : '',"name":"Name (Internal)"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":"name-internal","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.composeData.name),callback:function ($$v) {_vm.$set(_vm.composeData, "name", $$v)},expression:"composeData.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v(" When to Send: ")]),_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"show-when","name":"When"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"show-when","label":"name","placeholder":"When","options":_vm.eventTriggersDisplay,"state":_vm.getValidationState(
                        validationContext
                      )},model:{value:(_vm.happenWhen),callback:function ($$v) {_vm.happenWhen=$$v},expression:"happenWhen"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                        validationContext
                      )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"5"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"show-actionable-event","name":"Event"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('aom-events-select',{attrs:{"id":"show-actionable-event","options":_vm.eventsDisplayComputed,"state":_vm.getValidationState(
                        validationContext
                      )},model:{value:(_vm.actionableEvent),callback:function ($$v) {_vm.actionableEvent=$$v},expression:"actionableEvent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                        validationContext
                      )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(_vm.showCustomDateField)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"scheduledDate","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('aom-date-picker',{attrs:{"value-as-date":true,"min":_vm.minCustomDate},model:{value:(_vm.scheduledDate),callback:function ($$v) {_vm.scheduledDate=$$v},expression:"scheduledDate"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e()],1),(_vm.showPeriodField)?_c('b-col',{attrs:{"sm":"2"}},[_c('validation-provider',{attrs:{"vid":"time","rules":{ required: true },"name":"Period"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"id":"time","label":"#","placeholder":"Period","options":_vm.periodList,"state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1):_vm._e(),(_vm.showDurationField)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label-for":"show"}},[_c('validation-provider',{attrs:{"vid":"duration","rules":{ required: true },"name":"Duration"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"id":"duration","label":"name","placeholder":"Duration","options":_vm.durationUnitsDisplay,"state":_vm.getValidationState(
                        validationContext
                      )},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                        validationContext
                      )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)],1):_vm._e()],1)],1),(_vm.isProgramCommunications)?_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v(" Enable / Disable: ")]),_c('b-form-checkbox',{staticClass:"my-50",attrs:{"name":"is-rtl","switch":"","inline":""},model:{value:(_vm.composeData.is_enabled),callback:function ($$v) {_vm.$set(_vm.composeData, "is_enabled", $$v)},expression:"composeData.is_enabled"}})],1):_vm._e()]:_vm._e(),(!_vm.isLibraryComms)?_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v(" To: ")]),(_vm.type === _vm.emailComposeTypes.MESSAGE)?[_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"UsersIcon","size":"20"}}),(_vm.composeData && _vm.composeData.group)?_c('validation-provider',{staticClass:"flex-fill flex-1 email-to-selector col-sm-4",attrs:{"vid":"email-to-group","rules":{ required: _vm.isRequired },"name":"Group"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"id":"email-to-group-message","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToGroupOptions,"input-id":"email-to-group","loading":_vm.isLoading,"calculate-position":_vm.withPopper,"append-to-body":"","placeholder":"Select a Group","state":_vm.getValidationState(
                  validationContext
                )},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var name = ref.name;
                var type = ref.type;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(name))]),_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(type)+" ")])],1)],1)]}},{key:"selected-option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.group),callback:function ($$v) {_vm.$set(_vm.composeData, "group", $$v)},expression:"composeData.group"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)}):_vm._e(),_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}),_c('validation-provider',{staticClass:"flex-fill flex-grow-2 flex-2 email-to-selector",attrs:{"vid":"email-to","name":"User","rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"id":"email-to-user-message","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-to-group","loading":_vm.isLoading,"calculate-position":_vm.withPopper,"append-to-body":"","placeholder":"Select a User","state":_vm.getValidationState(
                  validationContext
                )},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
                var role = ref.role;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-avatar',{attrs:{"size":"md","variant":avatar? 'light': 'secondary',"text":("" + (name.charAt(0))),"src":avatar}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('span',{staticClass:"mr-1 ml-n3"},[_vm._v(" "+_vm._s(name))]),_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(role)+" ")])],1)],1)]}},{key:"selected-option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","variant":avatar? 'light': 'secondary',"src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.user),callback:function ($$v) {_vm.$set(_vm.composeData, "user", $$v)},expression:"composeData.user"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})]:(_vm.type === _vm.emailComposeTypes.SCHEDULED)?[[_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"UsersIcon","size":"20"}}),_c('validation-provider',{staticClass:"flex-fill flex-1 email-to-selector col-sm-4",attrs:{"vid":"email-to-group","rules":{ required: _vm.isRequired },"name":"Group"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(_vm.composeData && _vm.composeData.group)?_c('v-select',{class:validationContext.errors && validationContext.errors.length ?
                    'form-control is-invalid' :
                    '',attrs:{"id":"email-to-group","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToGroupOptions,"input-id":"email-to-group","loading":_vm.isLoading,"calculate-position":_vm.withPopper,"append-to-body":"","placeholder":"Select a Group","state":_vm.getValidationState(
                    validationContext
                  )},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var name = ref.name;
                  var type = ref.type;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(name))]),_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(type)+" ")])],1)],1)]}},{key:"selected-option",fn:function(ref){
                  var avatar = ref.avatar;
                  var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.group),callback:function ($$v) {_vm.$set(_vm.composeData, "group", $$v)},expression:"composeData.group"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)}),(_vm.hasProgramAdminRole)?_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}):_vm._e(),(_vm.hasProgramAdminRole)?_c('validation-provider',{staticClass:"flex-fill flex-grow-2 flex-2 email-to-selector",attrs:{"vid":"email-to","name":"User","rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"id":"email-to-user-message","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-to-group","loading":_vm.isLoading,"calculate-position":_vm.withPopper,"append-to-body":"","placeholder":"Select a User","state":_vm.getValidationState(
                    validationContext
                  )},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var avatar = ref.avatar;
                  var name = ref.name;
                  var role = ref.role;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-avatar',{attrs:{"size":"md","variant":avatar? 'light': 'secondary',"text":("" + (name.charAt(0))),"src":avatar}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('span',{staticClass:"mr-1 ml-n3"},[_vm._v(" "+_vm._s(name))]),_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(role)+" ")])],1)],1)]}},{key:"selected-option",fn:function(ref){
                  var avatar = ref.avatar;
                  var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","variant":avatar? 'light': 'secondary',"src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.user),callback:function ($$v) {_vm.$set(_vm.composeData, "user", $$v)},expression:"composeData.user"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)}):_vm._e()]]:(_vm.type === _vm.emailComposeTypes.INVITES)?[_c('validation-provider',{staticClass:"w-100",attrs:{"vid":"email-invite","rules":{ required: true, email_multi: true },"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"placeholder":"Enter Email Address Here","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.composeData.rawEmails),callback:function ($$v) {_vm.$set(_vm.composeData, "rawEmails", $$v)},expression:"composeData.rawEmails"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html.righttop",value:('Multiple emails should be separated by ;<br/> e.g., exam1@gmail.com;exam2@gmail.com'),expression:"'Multiple emails should be separated by ;<br/> e.g., exam1@gmail.com;exam2@gmail.com'",modifiers:{"hover":true,"html":true,"righttop":true}}],staticClass:"validation-icon",attrs:{"variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"20"}})],1)]:_vm._e()],2):_vm._e(),(_vm.type === _vm.emailComposeTypes.INVITES)?_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v(" Template: ")]),_c('validation-provider',{staticClass:"w-100",attrs:{"vid":"template-invite","rules":{},"name":"Template"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"flex-fill flex-1 email-to-selector",class:validationContext.errors && validationContext.errors.length ?
                  'form-control is-invalid' :
                  'form-control',attrs:{"label":"name","options":_vm.emailTemplateOptions,"placeholder":"Select Template","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCcField),expression:"showCcField"}],staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email-cc"}},[_vm._v("CC: ")]),_c('v-select',{staticClass:"flex-grow-1 email-cc-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-cc"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.cc),callback:function ($$v) {_vm.$set(_vm.composeData, "cc", $$v)},expression:"composeData.cc"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBccField),expression:"showBccField"}],staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email-bcc"}},[_vm._v("Bcc ")]),_c('v-select',{staticClass:"flex-grow-1 email-bcc-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-bcc"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
                var avatar = ref.avatar;
                var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.bcc),callback:function ($$v) {_vm.$set(_vm.composeData, "bcc", $$v)},expression:"composeData.bcc"}})],1),_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email-subject"}},[_vm._v("Subject: ")]),_c('validation-provider',{ref:"emailSubject",staticClass:"w-100",attrs:{"rules":"required|aom_sanitize","name":"Email Subject"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":"email-subject","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"message-editor"},[_c('validation-provider',{ref:"emailBody",attrs:{"rules":"required","vid":"emailBody","name":"Email Body"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(_vm.shallShowEmailComposeModal)?_c('aom-text-editor',{attrs:{"min-height":300,"state":_vm.getValidationState(
                validationContext
              ),"type":_vm.type},model:{value:(_vm.composeData.body),callback:function ($$v) {_vm.$set(_vm.composeData, "body", $$v)},expression:"composeData.body"}}):_vm._e(),_c('b-form-invalid-feedback',{staticClass:"p-50",attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }