<template>
  <div
    v-if="program && keyDates.length > 0"
    class="key-program-date-box"
  >
    <div
      class="k-title primary-color mb-2"
      role="heading"
    >
      Key Program Dates
    </div>
    <div class="k-list-key-date">
      <template v-for="(key, index) in keyDates">
        <div
          v-if="key.is_public"
          :key="index"
          class="k-list-key-date-item"
          :class="{ 'k-list-key-date-item-odd': index % 2 !== 0, 'cursor-pointer': key.has_detail_page && isLoggedIn }"
          tabindex="0"
          style="position: relative;"
        >
          <div
            class="k-detail"
            @click="openKeyDateModal(key)"
          >
            {{ key.title }}

            <aom-icon
              v-if="key.has_detail_page && isLoggedIn"
              icon="ClickableIcon"
              size="16"
            />
          </div>
          <div class="k-date">
            {{ formatDate(key.date) }}
          </div>
        </div>
      </template>
    </div>

    <b-modal
      v-model="showModal"
      :title="selectedKeyDate ? selectedKeyDate.title : ''"
      centered
      ok-only
      @ok.prevent="closeKeyDateModal"
    >
      <p>
        {{ selectedKeyDate ? formatDate(selectedKeyDate.date) : "" }} {{ programTimezone ? `(${programTimezone})` : "" }}
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="mb-0"
        v-html="DOMPurify.sanitize(selectedKeyDate ? selectedKeyDate.description : '')"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import AomIcon from "@aom-core/AomIcon";
import DOMPurify from 'dompurify';

export default {

  components: {
    BModal,
    AomIcon
  },
  data() {
    return {
      showModal: false,
      selectedKeyDate: null
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters['profile/isLoggedIn'];
    },

    program() {
      return this.$store.getters['participants/program'];
    },

    programTimezone() {
      if (this.program && this.program.time_zone) {
        return this.program.time_zone;
      }
      return '';
    },

    keyDates() {
      if (this.program && this.program.key_dates) {
        return this.program.key_dates.filter(date => date.is_public);
      }
      return [];
    }
  },

  methods: {
    formatDate(date) {
      return localeDateStringFromIsoDateTimeWithoutTimezone(date);
    },

    openKeyDateModal(keyDate) {
      if (this.isLoggedIn && keyDate.has_detail_page) {
        this.selectedKeyDate = keyDate;
        this.showModal = true;
      }
    },

    closeKeyDateModal() {
      this.showModal = false;
      this.selectedKeyDate = null;
    },
  },
  setup() {
    return {
      DOMPurify
    };
  },
};
</script>

<style lang="scss" scoped>
  .key-program-date-box {
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 1, 0.08);
    margin-top: 20px;

    .k-title {
      font-size: 24px;
      color: rgb(0, 42, 83);
      font-weight: 500;
      text-align: center;
      padding-top: 20px;
    }

    .k-list-key-date {
      .k-list-key-date-item {
        display: flex;
        align-items: center;
        align-content: center;
        padding: 15px 20px;

        .k-detail {
          font-size: 15px;
          color: rgb(76, 84, 109);
          line-height: 1.6;
          flex: 1;
          padding-right: 20px;
        }

        .k-date {
          font-size: 14px;
          color: rgb(76, 84, 109);
          line-height: 1.714;
          text-align: right;
        }
      }

      .k-list-key-date-item-odd {
        background-color: rgb(245, 247, 253);
      }
    }
  }
</style>