<template>
  <div v-if="isLoading">
    <question-skeleton-loader />
  </div>
  <b-card v-else>
    <b-row v-if="resource.type_id !== resourceTypes.WEB_PAGE">
      <b-col
        sm="12"
        class="text-left"
      >
        <h4>
          {{ resource.translations[0].title }}
          <template v-if="resource.type_id === resourceTypes.FILE">
            (<b-link @click="downloadResource">
              Download <feather-icon
                icon="DownloadIcon"
                size="16"
              />
            </b-link>)
          </template>
        </h4>
      </b-col>
    </b-row>
    <b-row v-if="resource.type_id === resourceTypes.URL">
      <b-col
        sm="12"
        class="text-left"
      >
        <h4>
          <a
            :href="resource.translations[0].link"
            target="_blank"
          >
            {{ resource.translations[0].link }}
            <feather-icon
              icon="ExternalLinkIcon"
              size="16"
            />
          </a>
        </h4>
      </b-col>
    </b-row>
    <b-row v-if="resource.type_id === resourceTypes.FILE">
      <b-col
        sm="12"
        class="text-left"
      >
        <b-img
          :src="resourceThumbnail"
          :alt="resource.translations[0].title"
          width="500px"
        />
      </b-col>
    </b-row>
    <div v-if="resource.type_id === resourceTypes.WEB_PAGE">
      <!-- eslint-disable vue/no-v-html -->
      <b-card class="mb-1">
        <b-row>
          <b-col
            sm="10"
            class="text-left"
          >
            <div v-html="DOMPurify.sanitize(resource.translations[0].page_title)" />
          </b-col>
          <b-col
            sm="2"
            class="text-right"
          >
            <feather-icon
              v-if="resource.is_public"
              icon="Share2Icon"
              size="20"
              style="cursor: pointer"
              @click="copyResourcePublicLink"
            />
            <feather-icon
              class="ml-2"
              icon="PrinterIcon"
              size="20"
              style="cursor: pointer"
              @click="printWebpage"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card
        id="content-to-print"
        class="mb-1"
      >
        <b-row>
          <b-col
            sm="12"
            class="text-left"
          >
            <div v-html="DOMPurify.sanitize(resource.translations[0].page_content)" />
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="resource.is_library">
        <b-row>
          <b-col
            sm="4"
            class="text-center"
          >
            info@artofmentoring.net
          </b-col>
          <b-col
            sm="4"
            class="text-center"
          >
            www.artofmentoring.net
          </b-col>
          <b-col
            sm="4"
            class="text-center"
          >
            {{ new Date().getFullYear() }} Art of Mentoring Holdings Pvt Ltd
          </b-col>
        </b-row>
      </b-card>
      <!-- eslint-disable -->
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { BCard, BRow, BCol, BImg, BLink } from "bootstrap-vue";
import QuestionSkeletonLoader from "@/views/components/questions/QuestionSkeletonLoader.vue";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import resourcesService from "@/services/resourcesService";
import { resourceTypes } from "@/models";
import {
  BE_API_URL,
  AOM_MAIN_DOMAIN,
  AOM_PORTAL_SUB_DOMAIN,
} from "@/constants/app";
import DOMPurify from 'dompurify';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    QuestionSkeletonLoader,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      resource: null,
      isLoading: false,
      resourceTypes,
      defaultThumbnail: require("@/assets/images/elements/default-resource-thumbnail.jpg"),
    };
  },
  computed: {
    ...mapGetters("participants", ["program"]),
    ...mapGetters("programs", ["defaultProgram"]),

    resourceThumbnail() {
      if (this.resource && this.resource.translations[0].thumbnail_url) {
        return this.resource.translations[0].thumbnail_url;
      }
      return this.defaultThumbnail;
    },
  },
  created() {
    if (this.token) {
      if (this.token === "public") {
        this.fetchPublicResource();
      } else {
        this.fetchResourceByToken();
      }
    } else {
      this.fetchResource();
    }
  },
  methods: {
    printWebpage() {
      window.print();
    },
    copyResourcePublicLink() {
      if (window.navigator) {
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}/${this.defaultProgram.client.path}/${this.program.path}/resources/${this.resource.id}/public`;
        window.navigator.clipboard.writeText(link);
        this.$toast(makeSuccessToast("Copied to clipboard."));
      }
    },
    async fetchResource() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getProgramResourceById(
          this.program.id,
          this.$route.params.resource
        );

        if (response && response.data) {
          this.resource = response.data;
        }
      } catch (e) {
        this.$toast(
          makeErrorToast(`Unable to fetch Program ${this.resourceId}.`)
        );
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchResourceByToken() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getPublicResourceByToken(
          this.$route.params.resource,
          this.token
        );

        if (response && response.data) {
          if (response.data.resource) {
            this.resource = response.data.resource;
          }
        }
      } catch (e) {
        const { data } = e.response;
        let loginRoute = { name: "auth-login" };
        if (data?.program) {
          loginRoute = {
            name: "participant-login",
            params: {
              clientSlug: data.program.client?.path,
              programPath: data.program.path,
            },
          };
        }
        this.$bvModal
          .msgBoxOk("Token is invalid or has expired.", {
            title: "Error",
          })
          .then(async () => {
            this.$router.push(loginRoute);
          });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchPublicResource() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getPublicResourceById(
          this.$route.params.resource
        );

        if (response && response.data) {
          if (response.data.resource) {
            this.resource = response.data.resource;
          }
        }
      } catch (e) {
        const { data } = e.response;
        let loginRoute = { name: "auth-login" };
        if (data?.program) {
          loginRoute = {
            name: "participant-login",
            params: {
              clientSlug: data.program.client?.path,
              programPath: data.program.path,
            },
          };
        }
        this.$bvModal
          .msgBoxOk("Token is invalid or has expired.", {
            title: "Error",
          })
          .then(async () => {
            this.$router.push(loginRoute);
          });
      } finally {
        this.isLoading = false;
      }
    },

    downloadResource() {
      let programId = null;
      let dowloadLink = null;
      const id = this.resource.id;
      const translationId = this.resource.translations[0].id;
      if (this.token) {
        if (this.token === "public") {
          dowloadLink = `${BE_API_URL}/resources/${id}/translations/${translationId}/public/download`;
        } else {
          dowloadLink = `${BE_API_URL}/resources/${id}/translations/${translationId}/tokens/${this.token}/download`;
        }
      } else {
        programId = this.program.id;
        dowloadLink = `${BE_API_URL}/programs/${programId}/resources/${id}/translations/${translationId}/download`;
      }
      window.document.location = dowloadLink;
    },
  },
  setup() {
    return {
      DOMPurify
    };
  },
};
</script>
<style lang="scss" scoped>
.card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
@media print {
  body * {
    visibility: hidden;
  }

  #content-to-print,
  #content-to-print * {
    visibility: visible;
  }

  #content-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
