import { 
  aggregate,
  aggregateTypes, 
  aggregateTypesDisplay, 
  operatorCommonDisplay,
  commonOperators,
  componentTypes,
  numericalStringDisplay,
  filters
} from "./advancedSearch";
import { matchRequestDisplaySelect, matchRequestStatus } from "./matchRequestStatus";

export const mentoringOnDemandEntitys = Object.freeze({
  MATCH_REQUESTS: 'match_requests',
});
export const entitysMentoringOnDemandDisplay =  [
  { id: mentoringOnDemandEntitys.MATCH_REQUESTS, name: 'Requests'},
];
// Matches
export const matchesRequestsFields = Object.freeze({
  REQUEST_STATUS: `${mentoringOnDemandEntitys.MATCH_REQUESTS}.status_id`,
});
export const matchesRequestsFieldsDisplay = [
  { id: matchesRequestsFields.REQUEST_STATUS, name: "Requests" },
];
export const mentoringOnDemandRules = {
  [mentoringOnDemandEntitys.MATCH_REQUESTS]: {
      field: matchesRequestsFieldsDisplay,
      op: {
        [matchesRequestsFields.REQUEST_STATUS]: [...operatorCommonDisplay],
      },
      component: {
        [matchesRequestsFields.REQUEST_STATUS]: componentTypes.DROPDOWN,
      },
      values: {
        [matchesRequestsFields.REQUEST_STATUS]: matchRequestDisplaySelect,
      },
      defaultValues: {
        [matchesRequestsFields.REQUEST_STATUS]: matchRequestStatus.ACCEPTED,
      },
      aggregate: { ...aggregate },
      service: {},
    }
};