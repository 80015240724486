
<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      v-model="showModal"
      title="Security Check"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :ok-disabled="isLoading"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
      @cancel="onCancel"
    >
      <b-form>
        <p>You are changing sensitive data please enter you password.</p>
        <input
          type="hidden"
          name="email"
          :value="userEmail"
        >
        <validation-provider
          #default="{ errors }"
          ref="password"
          name="Password"
          vid="password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="
              errors.length > 0
                ? 'is-invalid'
                : null
            "
          >
            <b-form-input
              id="login-password"
              v-model="password"
              :state="
                errors.length > 0 ? false : null
              "
              class="form-control-merge"
              :type="passwordFieldType"
              name="login-password"
              placeholder="Password"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="
                  togglePasswordVisibility
                "
              />
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback class="text-danger">
            {{
              errors[0]
            }}
          </b-form-invalid-feedback>
        </validation-provider>
        <p 
          v-if="isSamlSsoEnabled && isParticipantPortal" 
          class="mt-1"
        >
          Alternatively you can re-authenticate using
          <b-link
            :href="samlSsoHref"
          >
            Single Sign On (SSO)
          </b-link>.
        </p>
      </b-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import _debounce from "lodash/debounce";
import { authService } from '@services';
import store  from '@/store';
import { clearLocalStorage } from "@/auth/utils";
import { EventBus } from '@services/http.js';
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BInputGroup,
  BFormInput,
  BForm,
  BModal,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import {
  makeErrorToast,
} from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from 'vuex';

export default {
  components: {
    BModal,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BLink,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sessionTimer: null,
      showModal: true,
      isLoading: false,
      password: '',
    };
  },
  computed: {
    ...mapGetters('app', ['isParticipantPortal']),
    passwordToggleIcon() {
      return this.passwordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    userEmail() {
      return store.getters['profile/loggedUser']?.email || '';
    },
    samlSsoHref() {
      const program = this.$store.state.app.currentProgram;
      return program ? `/saml/sso/${program.id}` : '/saml/sso';
    },
    isSamlSsoEnabled() {
      const program = this.$store.state.app.currentProgram;
      return program !== null && program.program_saml.is_enabled;
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$emit('passwordVerified', this.password);
      this.isLoading = false;
    },
    onCancel() {
      this.isLoading = false;
      this.$emit('passwordCancelled');
    },
    onHidden() {
      this.password = '';
    }
  }
};
</script> 