<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-user-match-pool"
      v-model="showModal"
      size="lg"
      :title="`Add ${addingRole} for ${user.full_name}`"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-card>
        <b-form @submit.prevent="loadMatches">
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Search"
                label-for="Search"
              >
                <b-input-group class="mb-2">
                  <b-input-group-prepend class="search-icon">
                    <feather-icon
                      icon="SearchIcon"
                      size="14"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    id="`type-search`"
                    v-model="search"
                    type="search"
                    placeholder="Search"
                    class="pool-search"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isLoadingMatches"
              sm="12"
            >
              <aom-skeleton-loader :loader-type="loaderTypes.SHORT" />
            </b-col>
            <b-col
              v-else
              md="12"
            >
              <b-form-group v-slot="{ ariaDescribedby }">
                <validation-provider
                  ref="matched-user"
                  rules="required"
                  vid="matched-user"
                  name="Matched User"
                >
                  <div
                    v-if="matchedUsers.length === 0"
                    class="text-center"
                  >
                    No matches found
                  </div>
                  <b-form-radio-group
                    v-for="match in matchedUsers"
                    id="matched-users"
                    :key="match.id"
                    v-model="selectedMatch"
                    :aria-describedby="ariaDescribedby"
                    name="matched-users"
                    class="mb-1"
                  >
                    <b-form-radio
                      v-if="!Boolean(match.match_limit_exceeded)"
                      :value="match.id"
                      class="grid-item-1"
                    >
                      {{ match.full_name }}
                    </b-form-radio>
                    <label
                      v-else
                      class="locked-users"
                    >
                      {{ match.full_name }}
                      <aom-icon
                        icon="LockIcon"
                        size="20"
                      />
                    </label>
                    <div class="grid-item-2">
                      <h6 class="text-muted text-center">
                        {{ match.compatibility }} %
                      </h6>
                      <b-progress
                        :value="match.compatibility"
                        max="100"
                        show-progress
                        show-value
                        animated
                        variant="success"
                      />
                    </div>
                    <b-button
                      v-b-toggle="`collapse-${match.id}`"
                      variant="outline-primary"
                      class="btn-icon grid-item-3"
                      @click="fetchMatchQuestions(match.id)"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                      />
                    </b-button>
                    <b-collapse
                      :id="`collapse-${match.id}`"
                      class="mt-2 grid-item-4 collapse-panel"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card class="text-center">
                        <section
                          v-if="
                            !isLoadingQuestions && menteeUser && menteeUser.user
                          "
                        >
                          <b-row>
                            <b-col sm="6">
                              <b-row>
                                <b-col sm="9">
                                  <span
                                    class="mb-0 d-flex justify-content-end"
                                  >{{ menteeUser.user.full_name }}</span>
                                  <span
                                    class="mb-0 d-flex justify-content-end"
                                  ><small class="text-muted">
                                    {{ menteeUser.user.email }}</small></span>
                                </b-col>
                                <b-col
                                  sm="3"
                                  class="d-flex justify-content-end"
                                >
                                  <b-avatar
                                    variant="primary"
                                    :text="userInitials(menteeUser.user)"
                                    :src="userProfileImage(menteeUser.user)"
                                    size="4rem"
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="6">
                              <b-row>
                                <b-col sm="3">
                                  <b-avatar
                                    variant="primary"
                                    :text="userInitials(mentorUser.user)"
                                    :src="userProfileImage(mentorUser.user)"
                                    size="4rem"
                                  />
                                </b-col>
                                <b-col sm="9">
                                  <span
                                    class="mb-0 d-flex justify-content-start"
                                  >{{ mentorUser.user.full_name }}</span>
                                  <span
                                    class="mb-0 d-flex justify-content-start"
                                  ><small class="text-muted">
                                    {{ mentorUser.user.email }}</small></span>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="6">
                              <aom-languages align="right">
                                <template slot-scope="props">
                                  <application-summary
                                    :user="menteeUser"
                                    :index="props.index"
                                    :locale-id="props.localeId"
                                    :questions="menteeQuestions"
                                    :matched-users-questions="mentorQuestions"
                                  />
                                </template>
                              </aom-languages>
                            </b-col>
                            <b-col sm="6">
                              <aom-languages>
                                <template slot-scope="props">
                                  <application-summary
                                    :user="mentorUser"
                                    :index="props.index"
                                    :locale-id="props.localeId"
                                    :questions="mentorQuestions"
                                    :matched-users-questions="menteeQuestions"
                                  />
                                </template>
                              </aom-languages>
                            </b-col>
                          </b-row>
                        </section>
                        <section v-else>
                          Loading ...
                        </section>
                      </b-card>
                    </b-collapse>
                  </b-form-radio-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="total > 0">
            <b-col sm="12">
              <aom-pagination
                :pages="total"
                :per-page="perPage"
                @page-change="loadNextPage"
              />
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="md"
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          size="md"
          variant="primary"
          :disabled="pristine || invalid"
          @click="ok(onSubmit)"
        >
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span v-if="isUpdating"> Updating...</span>
          <span v-else>Confirm and Lock</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup,
  BForm,
  BModal,
  BFormRadio,
  BCollapse,
  BButton,
  VBToggle,
  BSpinner,
  BAvatar,
  BInputGroupPrepend,
  BProgress,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import matchesService from "@/services/matchesService";
import { getValidationState } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from "vuex";
import {
  loaderTypes,
  ProgramMatches,
  matchStatus,
  userRoles,
  userRolesDisplay,
} from "@models";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import AomLanguages from "@aom-core/AomLanguages";
import { questionTypes } from "@models/questionTypes";
import ApplicationSummary from "@/views/components/forms/applications/ApplicationSummary.vue";
import _debounce from "lodash/debounce";
import AomPagination from "@/@aom-core/AomPagination.vue";
import AomIcon from "@aom-core/AomIcon";

export default {
  name: "AddUserToMatchPool",
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BCollapse,
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    AomSkeletonLoader,
    BSpinner,
    BAvatar,
    AomLanguages,
    ApplicationSummary,
    BInputGroup,
    BInputGroupPrepend,
    BProgress,
    AomPagination,
    AomIcon
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    role: {
      type: Number,
      validator(value) {
        return Object.values(userRoles).indexOf(value) !== -1;
      },
      required: true,
    },
    currentUserRole: {
      type: Number,
      validator(value) {
        return Object.values(userRoles).indexOf(value) !== -1;
      },
      required: true,
    },
    match: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      isLoadingMatches: false,
      matchedUsers: [],
      menteeUser: {},
      menteeQuestions: [],
      mentorQuestions: [],
      mentorUser: {},
      selectedMatch: undefined,
      isLoadingQuestions: false,
      isUpdating: false,
      search: "",
      total: 0,
      page: 1,
      perPage: 10,
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgramId"]),
    addingRole() {
      return this.role === userRoles.MENTEE
        ? userRolesDisplay[userRoles.MENTEE]
        : userRolesDisplay[userRoles.MENTOR];
    },
  },
  watch: {
    user(n) {
      if (n && n.id) {
        this.loadMatches();
      }
    },
    search: _debounce(function (newVal) {
      this.loadMatches(newVal);
    }, 1000),
  },
  methods: {
    loadNextPage(currentPage) {
      this.page = currentPage;
      this.loadMatches();
    },
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.isLoadingMatches = false;
      this.matchedUsers = [];
      this.menteeUser = {};
      this.mentorUser = {};
      this.selectedMatch = undefined;
      this.isLoadingQuestions = false;
      this.isUpdating = false;
      this.search = "";
      this.page = 1,
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$emit("clear");
    },
    userInitials(user) {
      if (user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    async loadMatches() {
      let filter = {
        page: this.page,
        perPage: this.perPage,
        columnFilters: [],
      };
      if (this.search) {
        filter.columnFilters.push({
          field: "keyword",
          value: this.search,
        });
      }
      if (this.user === undefined || this.user.id === undefined) {
        return;
      }
      try {
        const programId = this.defaultProgramId;
        const poolId = this.$route.params.poolId;
        const userId = this.user.id;
        const role = this.role;
        this.isLoadingMatches = true;
        const response = await matchesService.listPoolMatchCandidatesForUser(
          programId,
          poolId,
          userId,
          role,
          filter
        );
        const { data } = response;
        const { items, total } = data;
        this.matchedUsers = items;
        this.total = total;
      } catch (e) {
        console.log(e);
        this.$log.error(e);
        this.$toast(makeErrorToast("Error. Fetching candidates list"));
      } finally {
        this.isLoadingMatches = false;
      }
    },
    async fetchMatchQuestions(id) {
      const matchUsers = [
        // Matched User
        {
          user_id: id,
          role_id: this.role,
        },
        // Current User
        {
          user_id: this.user.id,
          role_id: this.currentUserRole,
        },
      ];
      const programId = this.defaultProgramId;
      try {
        this.isLoadingQuestions = true;
        const response = await matchesService.getCompareUsers(
          programId,
          matchUsers
        );
        const { data } = response;
        this.menteeUser = data.match_users
          .filter(m => m.role_id === userRoles.MENTEE)
          .shift();
        this.mentorUser = data.match_users
          .filter(m => m.role_id === userRoles.MENTOR)
          .shift();
        this.menteeQuestions =
          this.menteeUser?.user.user_forms[0]?.form?.questions;
        this.mentorQuestions =
          this.mentorUser?.user.user_forms[0]?.form?.questions;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Error. Fetching application questions"));
      } finally {
        this.isLoadingQuestions = false;
      }
    },
    userProfileImage(user) {
      return user?.profile_image?.url || "";
    },
    onSubmit() {
      this.$bvModal
        .msgBoxConfirm(
          "This new match will be locked in the pool. This action may cause any unlocked matches to be reshuffled.?",
          {
            title: "Confirm Locked Match",
            size: "sm",
            okVariant: "primary",
            okTitle: "Confirm",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            const match = new ProgramMatches({
              status_id: matchStatus.LOCKED,
              match_users: [
                { user_id: this.user.id, role_id: this.currentUserRole },
                { user_id: this.selectedMatch, role_id: this.role },
              ],
            });
            const matchId = this.match.id;
            try {
              this.isUpdating = true;
              const response = await matchesService.patchUpdateMatch(
                this.defaultProgramId,
                matchId,
                match
              );
              this.$toast(makeSuccessToast("Match Locked successfully."));
              this.showModal = false;
              this.$emit("published", response);
            } catch (e) {
              const { data } = e && e.response;
              if (data.message) {
                return this.$toast(makeErrorToast(data.message));
              }
              this.$toast(makeErrorToast(e));
              this.$log.error(e);
            } finally {
              this.isUpdating = false;
            }
          }
        });
    },
  },
  setup() {
    return {
      getValidationState,
      userRoles,
      loaderTypes,
      questionTypes,
      matchStatus
    };
  },
};
</script>
<style lang="scss" scoped>
.locked-users {
  padding-left: 1.8rem;
  font-size: 1rem;
}
.locked-users .icon{
  margin-bottom: 7px;
}
#matched-users {
  display: grid;
  grid-template-columns: 50% 0 40% auto;
  align-items: center;
}
.grid-item-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.grid-item-2 {
  grid-column-start: 3;
}
.grid-item-3 {
  justify-self: end;
}
.grid-item-4 {
  grid-column: 1 / 5;
}
.collapse-panel {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.search-icon {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-right: none;
  margin-right: -5px;
}
.pool-search {
  border-left: none;
}
</style>
