<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    scrollable
    no-enforce-focus
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5
        class="modal-title"
        role="heading"
      >
        Compose Mail
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="
            isContactProgramManager
              ? onCloseModal()
              : discardEmail()
          "
        />
      </div>
    </template>
  
    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          variant="primary"
          right
          @click="sendEmail"
        >
          <feather-icon
            icon="SendIcon"
            class="mr-1 cursor-pointer"
          />
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span v-if="isUpdating">Sending...</span>
          <span v-else>Send</span>
        </b-button>
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(sendEmail)">
        <!-- Public email compose -->
        <div v-if="isPublic">
          <div
            v-if="!isContactProgramManager"
            class="compose-mail-form-field justify-content-start"
          >
            <label for="name">Name: </label>
            <validation-provider
              v-slot="validationContext"
              ref="name"
              rules="required|aom_sanitize"
              name="Name"
            >
              <b-form-input
                id="name"
                v-model="guestInfo.name"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div
            v-if="!isContactProgramManager"
            class="compose-mail-form-field justify-content-start"
          >
            <label for="phone">Phone: </label>
            <validation-provider
              v-slot="validationContext"
              ref="phone"
              rules="required"
              name="Phone"
              class="w-100"
            >
              <b-form-input
                id="phone"
                v-model="guestInfo.phone"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div
            v-if="!isContactProgramManager"
            class="compose-mail-form-field justify-content-start"
          >
            <label for="email">Email: </label>
            <validation-provider
              v-slot="validationContext"
              ref="email"
              rules="required|email"
              name="Email"
              class="w-100"
            >
              <b-form-input
                id="email"
                v-model="guestInfo.email"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
        </div>
      
        <!-- Normal email compose -->
        <!-- Field: To -->
        <div
          v-else
          class="compose-mail-form-field justify-content-start"
        >
          <label
            for="email-to"
            class="form-label"
          >To: </label>
          <feather-icon
            icon="UserIcon"
            size="20"
          />
          <validation-provider
            v-slot="validationContext"
            vid="email-to"
            :rules="{ required: true }"
            name="User"
            class="flex-fill flex-grow-2 flex-2 email-to-selector"
          >
            <v-select
              v-model="composeData.user"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :close-on-select="false"
              :options="emailToOptions"
              input-id="email-to"
              :loading="isLoading"
              :calculate-position="withPopper"
              append-to-body
              placeholder="Select a User"
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              <template #option="{ avatar, name, role }">
                <b-row>
                  <b-col sm="2">
                    <b-avatar
                      class="mr-1"
                      size="md"
                      :src="avatar"
                      :variant="avatar? 'light': 'secondary'"
                      :text="`${name.charAt(0)}`"
                    />
                  </b-col>
                  <b-col
                    sm="9"
                  >
                    <span class="mr-1 ml-n3"> {{ name }}</span>
                    <b-badge
                      v-if="role !== undefined"
                      variant="secondary"
                    >
                      {{ role }}
                    </b-badge>
                  </b-col>
                </b-row>
              </template>

              <template #selected-option="{ avatar, name }">
                <b-avatar
                  size="sm"
                  class="border border-white"
                  :variant="avatar? 'light': 'secondary'"
                  :src="avatar"
                />
                <span class="ml-50"> {{ name }}</span>
              </template>
            </v-select>
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
  
        <!-- Field: Cc (Hidden Initially) -->
        <div
          v-show="showCcField"
          class="compose-mail-form-field justify-content-start"
        >
          <label for="email-cc">CC: </label>
          <v-select
            v-model="composeData.cc"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            class="flex-grow-1 email-cc-selector"
            :close-on-select="false"
            :options="emailToOptions"
            input-id="email-cc"
          >
            <template #option="{ avatar, name }">
              <b-avatar
                size="sm"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
  
            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select>
        </div>
  
        <!-- Field: Bcc (Hidden Initially) -->
        <div
          v-show="showBccField"
          class="compose-mail-form-field justify-content-start"
        >
          <label for="email-bcc">Bcc </label>
          <v-select
            v-model="composeData.bcc"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            class="flex-grow-1 email-bcc-selector"
            :close-on-select="false"
            :options="emailToOptions"
            input-id="email-bcc"
          >
            <template #option="{ avatar, name }">
              <b-avatar
                size="sm"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
  
            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select>
        </div>
  
        <!-- Field: Subject -->
        <div class="compose-mail-form-field justify-content-start">
          <label for="email-subject">Subject: </label>
          <validation-provider
            v-slot="validationContext"
            ref="emailSubject"
            rules="required|aom_sanitize"
            name="Email Subject"
            class="w-100"
          >
            <b-form-input
              id="email-subject"
              v-model="composeData.subject"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
        <div class="message-editor">
          <validation-provider
            v-slot="validationContext"
            ref="emailBody"
            rules="required"
            vid="emailBody"
            name="Email Body"
          >
            <aom-text-editor
              v-if="shallShowEmailComposeModal"
              v-model="composeData.body"
              :min-height="300"
              :tool-bar="toolBar"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
              class="p-50"
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
  
<script>
import { mapGetters } from "vuex";
import {
  BFormInvalidFeedback, BForm, BFormInput, BAvatar, BBadge, BRow, BCol, BButton, BSpinner
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import { usersService, commsService }  from "@/services";
import AomTextEditor from '@aom-core/AoMTextEditor.vue';
import { createPopper } from '@popperjs/core';
import { TopicClass,TopicRecipient, topicChannel, topicTypes, recipientTypes, userStatus } from '@models';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
//eslint-disable-next-line
import { required } from "@validations";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _isEqual from 'lodash/isEqual';

export default {
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormInput,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    AomTextEditor,
    vSelect,
    BFormInvalidFeedback,
    BSpinner
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      default: 0
    },
    isPublic: {// Used for public landing page
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toolBar: 'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | link image | help ',
      isLoading: false,
      isLoadingGroups: false,
      isUpdating: false,
      emailToOptions: [],
      programChampions: [],
      emailPlugins: [
        'advlist', 'autolink','lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor',
        'searchreplace', 'visualblocks', 'code',
        'insertdatetime', 'media', 'table', 'paste', 'wordcount'],
      composeData:{
        user: [],
        group:[],
        subject: undefined, 
        body: undefined
      },
      guestInfo: {
        name: "",
        phone: "",
        email: "",
      },
      program: null
    };
  },
  computed: {
    ...mapGetters('profile', ['isLoggedIn']),

    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },
    participantCurrentProgram() {
      return this.$store.getters['participants/program'];
    },
    profile() {
      return this.$store.getters["profile/profile"];
    },
    isContactProgramManager() {
      return this.$route.query.isContactProgramManager || false;
    },
    programManager() {
      const programManager = this.$store.getters['participants/program']?.default_champion;
      return {
        id: programManager.id,
        email: programManager.email,
        name: programManager.full_name,
        role: "champion"
      };
    },
  },
  watch: {
    '$route.query': {
      handler: function(query) {
        if (query.isContactProgramManager) {
          this.guestInfo = {
            name: this.profile.full_name,
            phone: this.profile.phone,
            email: this.profile.email
          };
          this.composeData.user = [
            this.programManager
          ];
        }
      },
      deep: true,
      immediate: true
    },
    shallShowEmailComposeModal(n) {
      if (!n) {
        this.resetForm();
      }
    },
    userId: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if (n) {
          const user = this.emailToOptions.find(e => Number(e.id) === Number(n));
          if (user) {
            this.composeData.user = [
              ...this.composeData.user,
              user
            ];
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    if (!this.isPublic) {
      await this.getProgramParticipantsList();
    }

    if (this.$route.query.userId) {
      const user = this.emailToOptions.find(e => e.id == this.$route.query.userId);
      this.composeData.user = [
        ...this.composeData.user,
        user
      ];
    } else if (this.userId) {
      const user = this.emailToOptions.find(e => e.id == this.userId);
      this.composeData.user = [
        ...this.composeData.user,
        user
      ];
    }
    if (this.isLoggedIn && this.appCurrentProgram) {
      const programId = this.appCurrentProgram.id;
      const programChampionsResponse = await usersService.getChampionsAndPAByProgram(programId, {
        columnFilters: [
          { field: "status", "value": userStatus.ACTIVE}
        ]
      });
      this.programChampions = programChampionsResponse.data.items.map(function (item) {
        return {
          recipient_id: item.id,
          recipient_type: recipientTypes.USER,
          raw_email_address: item.email,
        };
      });
    }
  },
  methods: {
     async getProgramParticipantsList() {
      try {
        this.isLoading = true;
        const programId = this.participantCurrentProgram.id;
        let items = [];
        if (this.participantCurrentProgram.participant_visibility) {
          const response = await usersService.getListParticipantsByProgram(
            programId
          );
          items = response.data.items;
          const seen = new Set();
          const filteredArr = items.filter(el => {
            const duplicate = seen.has(el.id);
            seen.add(el.id);
            return !duplicate;
          });
          this.emailToOptions = filteredArr.map(user => ({
            id: user.id,
            email: user.email,
            name: user.full_name,
            role: user.user_roles
              .filter(role =>  role.role.name !== "Participant Candidate")
              .map(r => r.role.name[0].toUpperCase() + r.role.name.slice(1))
              .join(", "),
            avatar: user.profile_image?.url || "",
          }));
        } else {
          // If we dont have full visibility then we need to get the matches
          const response = await usersService.getListUserMatches(
            programId,
            this.profile.id
          );
          items = response.data.items;
          items = items.map(i => {
            let users = i.match_users.filter(
              i => i.user_id !== this.profile.id
            );
            return users.length ? users[0] : null;
          });
          this.emailToOptions = items.map(i => ({
                id: i.user_id,
                email: i.user.email,
                name: i.user.full_name,
                role: i.user.user_roles
                  .filter(role =>  role.role.name !== "Participant Candidate")
                  .map(r => r.role.name[0].toUpperCase() + r.role.name.slice(1))
                  .join(", "),
                })      
          );
        }
        this.emailToOptions.unshift({
          id: null,
          name: "Program Manager",
        });
      } catch (e) {
        this.$toast(makeErrorToast("Participants email list not loaded."));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    sendEmail() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) return;
        try {
          this.isUpdating = true;
          const programId = this.appCurrentProgram.id;
          const message = this.getEmailMessage();
          if (this.isPublic) {
            const payload = {
              ...message,
              ...this.guestInfo
            };
            await commsService.postContactProgramManager(programId, payload);
          } else {
            await commsService.postCreateTopic(programId, message);
          }
          this.$emit('update:shall-show-email-compose-modal', false);
          this.$emit('emailAdded', message);
          this.$toast(makeSuccessToast("Email sent."));
          if (this.userId) {
            this.$router.push({
              name: "participant-messages",
            });
          }
        } catch(e) {
          this.$toast(makeErrorToast("Email not sent please try again."));
          console.log(e);
        } finally {
          this.isUpdating = false;
        }
      });      
    },
    updateMessageBody(body) {
      this.composeData.body = body;
    },
    getEmailMessage() {
      let participants = this.composeData.user.filter(u => u.id !== null);
      let recipients = [
        ...participants.map(u => new TopicRecipient(
          {
            recipient_id: u.id,
            recipient_type: recipientTypes.USER,
            raw_email_address: u.email
          }) 
        ), 
      ];
      if (participants.length !== this.composeData.user.length) {
        recipients.push(...this.programChampions);
      }
      const message = new TopicClass({
        subject: this.composeData.subject,
        body: this.composeData.body,
        channel_id: topicChannel.EMAIL,
        type_id: topicTypes.MESSAGE,
        recipients: recipients,
      });
      return message;
    },
    withPopper(dropdownList, component) {
      dropdownList.style.width = '100%';
      dropdownList.style.maxWidth = "550px";
      dropdownList.style.maxHeight = "300px";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    resetForm() {
      this.$refs.observer.reset();
      this.composeData = {
        user: [],
        group:[],
        rawEmails: undefined,
        subject: undefined,
        body: undefined
      };
    },
    onCloseModal() {
      this.$router.push({
        name: this.$route.name,
        query: {}
      });
    }
  },
  setup(_, { emit }) {
    // const composeData = ref({});
    const showCcField = ref(false);
    const showBccField = ref(false);
  
    const discardEmail = () => {
      emit('update:shall-show-email-compose-modal', false);
    };
  
    return {
      showCcField,
      showBccField,
      discardEmail,
      getValidationState
    };
  },
};
</script>
  
<style lang="scss">
  @import '@/assets/scss/vue/libs/vue-select.scss';
  @import '@/assets/scss/vue/libs/quill.scss';
  [data-popper-placement='bottom'] {
    border-radius: 4px 4px 0 0;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
    z-index: 9999;
  }
  .v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
    z-index: 9999;
  }
</style>
  
  <style lang="scss" scoped>
  form ::v-deep {
  
    // Mail To vue-select style
    .v-select {
      .vs__dropdown-toggle {
        border: 0;
        box-shadow: none;
      }
      .vs__open-indicator {
        display: none;
      }
    }
  
    // Quill Editor Style
    .quill-editor {
      .ql-container.ql-snow {
        border-bottom: 0 !important;
      }
    }
  }
  </style>