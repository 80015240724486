<template>
  <vue-good-table
    mode="remote"
    class="position-static"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="isRtl"
    :search-options="{
      enabled: false,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Status -->
      <span v-if="props.column.field === 'status'">
        <b-badge :variant="STATUS_COLOR[getMessageStatus(props.row)]">
          {{ getMessageStatus(props.row) }}
        </b-badge>
      </span>
      <div 
        v-else-if="props.column.field === 'subject'"
        class="d-flex"
      >
        <router-link
          v-if="handleMessageLink(props.row)"
          :to="getMessageLink(props.row)"
        >
          <div>{{ props.formattedRow[props.column.field] }}</div>
          <div class="mail-type">
            <b-badge :variant="getBadgeVariation(props.row)">
              {{ getMessageLabel(props.row) }}
            </b-badge>
          </div>
        </router-link>
        <div v-else>
          <div>{{ props.formattedRow[props.column.field] }}</div>
          <div class="mail-type">
            <b-badge :variant="getBadgeVariation(props.row)">
              {{ getMessageLabel(props.row) }}
            </b-badge>
          </div>
        </div>
      </div>

      <!-- Column: Common -->
      <span v-else>
        <span>{{ props.formattedRow[props.column.field] }}</span>
      </span>
    </template>
    
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="
          (value) => props.pageChanged({ currentPage: value })
        "
      />
    </template>
  </vue-good-table>
</template>
    
<script>
import { BBadge} from "bootstrap-vue";
import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_TIME_FORMAT, localeDateStringFromIsoDateTime } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { messageStatusDisplay, topicTypes } from '@models';
import { commsService } from '@/services';
import { mapGetters } from 'vuex';
import { STATUS_COLOR, makeErrorToast } from "@/libs/utils";
    
export default {
  name: 'ParticipantCommunications',
  components: {
    BBadge,
    VueGoodTable,
    TablePagination
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      perPage: 10,
      page: 1,
      columns: [        
        {
          label: "Subject",
          field: "subject",
          sortable: false,
        },
        {
          label: "Status",
          field: 'status',
          sortable: false,
          width: "5em",
        },
        {
          label: "Sent at",
          field: "created_at",
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: "Sent at",
          },
          width: "10em",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_TIME_FORMAT,
          thClass: "vgt-left-align",
          tdClass: "vgt-left-align"
        },
        {
          label: "Read at",
          field: "email_opened_at",
          sortable: false,
          width: "10em",
          thClass: "vgt-left-align",
          tdClass: "vgt-left-align"
        },
        {
          label: "Times read",
          field: "email_opened_count",
          sortable: false,
          width: "10em",
          thClass: "vgt-left-align",
          tdClass: "vgt-left-align"
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [{field: "updated_at", type:'desc'}],
      total: 0,
      STATUS_COLOR
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'forceRefreshParticipantMatches']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    getMessageStatus(row) {
      return messageStatusDisplay[row.status_id];
    },
    handleMessageLink(row) {

      if(!row.topic.created_by_id){
        return row.topic.type_id !== topicTypes.SYSTEM_NOTIFICATION;
      }else{
        return !row.private_message;
      }
    },
    getBadgeVariation(row) {
            if(row.topic.is_schedulable){
              return 'light-info';
            }
            if( row.topic.type_id === topicTypes.SYSTEM_NOTIFICATION){
              return 'light-success';
            }
            return 'light-primary';
    },
    getMessageLabel(row) {
      if (row.topic.is_schedulable) {
        return 'Scheduled';
      }
      return topicTypes.SYSTEM_NOTIFICATION === row.topic.type_id ? 'System': 'Direct messages';
    },
    getMessageLink(row) {
      const topic = row.topic;
      if (topic.is_schedulable) {
        return {
          name: 'champion-program-schedules',
          params: { id: topic.program_id },
          query: {
            scheduledId: topic.id
          }
        };
      } else if (topic.type_id === topicTypes.INVITE) {
        return {
          name: 'champion-program-invites',
          params: { id: topic.program_id },
          query: {
            id: topic.id
          }
        };
      }
      return {
        name: 'champion-program-communications',
        params: { id: topic.program_id },
        query: {
          id: topic.id
        }
      };
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter() {
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const userId = this.user.id;
        const response = await commsService.getSentMessagesByUser(programId, userId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items.map(item => {
          return {
            ...item,
            email_opened_count: parseInt(item.email_opened_count) === 0 ? '-' : parseInt(item.email_opened_count),
            email_opened_at: item.email_opened_at? localeDateStringFromIsoDateTime(item.email_opened_at, true): '-'
          }
        });
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }           
    }
  },
  setup() {
    return {
      topicTypes
    };
  }
};
</script>
    
<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
    background-image: none !important;
}
.mail-type {
  margin-right: 20px;
  margin-left: auto;
}
</style>
    