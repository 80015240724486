export const matchRequestStatus = Object.freeze({
  PENDING:  1,
  ACCEPTED: 2,
  DECLINED: 3,
  WITHDRAWN: 4,
  EXPIRED: 5
});

export const matchRequestDisplay = {
  [matchRequestStatus.PENDING]: 'Pending',
  [matchRequestStatus.ACCEPTED]: 'Accepted',
  [matchRequestStatus.DECLINED]: 'Declined',
  [matchRequestStatus.WITHDRAWN]: 'Withdrawn',
  [matchRequestStatus.EXPIRED]: 'Expired'
};

export const matchRequestDisplaySelect = [
  {value: matchRequestStatus.PENDING, text: 'Pending'},
  {value: matchRequestStatus.ACCEPTED, text: 'Accepted'},
  {value: matchRequestStatus.DECLINED, text: 'Declined'},
  {value: matchRequestStatus.WITHDRAWN, text: 'Withdrawn'},
  {value: matchRequestStatus.EXPIRED, text: 'Expired'},
];